import { useState, useEffect } from "react";

export const getLocalStorageValue = (key, defaultValue) => {
  const localStorageValue = localStorage.getItem(key);
  const value = localStorageValue === "undefined" ? undefined : JSON.parse(localStorageValue);
  return value !== undefined && value !== null ? value : defaultValue;
};

export const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setLocalStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
