import { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { LatLng } from "leaflet";
import { Marker, useMap } from "react-leaflet";
import PopupMenu from "./PopupMenu";
import { ToolContext } from "../context/ToolContext";
import { getNearbySubstations } from "../services/apiService";
import { iconMarker } from "../utils/iconMarker";
import { useDebounce } from "../utils/useDebounce";
import { getTransformerSearchValue } from "../utils/transformerFunctions";
import { getRagNetwork } from "../services/apiService";
import { FormContext } from "../context/FormContext";
import {
  createRagNetworkFromResponse,
  createRagNetworkFromSubstation,
} from "../model/viewModel/ragNetworkFactory";
import { useSelector } from "react-redux";
import { checkReduxNetworkPopulated } from "../app/networkSlice";
import * as tab from "../constants/tabs";
import useUserInfoAndLogging from "../utils/useUserInfoAndLogging";
import * as alert from "../constants/alert";
import useBearerToken from "../utils/useBearerToken";

const NearbyAssets = ({ assetType }) => {
  const map = useMap();
  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  const SUBSTATION = "substation";
  const SMALL_NETWORK_CABLE_THRESHOLD = 10;
  const MAX_MAP_BOUNDS = 5000;
  const { logException } = useUserInfoAndLogging();
  const { toolState, setToolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { mode, activeTool, selectedAssetId } = toolState;
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  const [mapCenter, setMapCenter] = useState(map.getCenter());
  const [useAutoZoomLevel, setUseAutoZoomLevel] = useState(false);
  const [mapBoundDistance, setMapBoundDistance] = useState(300);
  const [nearbyAssets, setNearbyAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const debouncedMapCenter = useDebounce(mapCenter, 500);
  const debouncedZoomLevel = useDebounce(zoomLevel, 500);
  const debouncedAutoZoom = useDebounce(useAutoZoomLevel, 500);
  const { clientSettings } = formState;
  const overrideRagAssetZoom = clientSettings.map.projection === "EPSG27700" ? 10 : 18;
  const ragAssetZoomLevel = parseInt(
    clientSettings.general.cableRAGZoomLevel ?? overrideRagAssetZoom,
  );
  const ragAssetSmallNetworkZoomLevel = ragAssetZoomLevel + 1;
  const iconSize = useSelector((state) => state.settings.iconSize);

  const { TransformerNameSearchEnabled, RAGCablesEnabled, DisplaySiteNumberEnabled } =
    clientSettings.features;

  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

  const transformers = useSelector((state) => state.network.present.transformers);

  useEffect(() => {
    if (debouncedZoomLevel) {
      getNearbyAssets();
    }
  }, [debouncedMapCenter, debouncedZoomLevel, debouncedAutoZoom]);

  useEffect(() => {
    if (!selectedAssetId) {
      clearSelection();
      return;
    }

    const newSelectedAsset = nearbyAssets.find((a) => a.id === selectedAssetId);

    if (newSelectedAsset && newSelectedAsset !== selectedAsset) {
      setSelection(newSelectedAsset);
    }
  }, [selectedAssetId, nearbyAssets]);

  map.once("zoomend", function () {
    const bounds = map.getBounds();
    const boundsDistance = bounds.getSouthEast().distanceTo(bounds.getNorthWest());

    setMapBoundDistance(Math.ceil(boundsDistance));
    setZoomLevel(map.getZoom());
  });

  map.once("moveend", function () {
    const currentCenter = map.getCenter();
    const previousCenter = mapCenter;

    if (currentCenter.equals(previousCenter)) {
      return;
    }

    if (previousCenter.distanceTo(currentCenter) > mapBoundDistance / 2) {
      setMapCenter(currentCenter);
    }
  });

  map.on("resize", function () {
    map.invalidateSize();
  });

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const getNearbyAssets = async () => {
    if (mapBoundDistance > MAX_MAP_BOUNDS) {
      return;
    }

    if (assetType === SUBSTATION) {
      const token = await getToken(instance, accounts);
      const nearbySubstations = await getNearbySubstations(
        token,
        mapCenter.lat,
        mapCenter.lng,
        mapBoundDistance,
      );
      if (nearbySubstations instanceof Error) {
        logException(nearbySubstations);
      }
      if (!nearbySubstations || !nearbySubstations.data) {
        return;
      }

      const assets = nearbySubstations.data.map((s, index) => ({
        ...s,
        name: DisplaySiteNumberEnabled ? `${s.name} (${s.number})` : `${s.name}`,
        key: `${s.id}_${index}`,
        type: SUBSTATION,
        position: new LatLng(s.centroid.lat, s.centroid.lng),
        hasColocatedAssets: s.colocatedIds?.length > 1,
        styles: {
          bgColor: selectedAsset && selectedAsset.id === s.id ? "primary" : "dark",
          borderColor: "transparent",
          circleMarker: true,
          class: "substation-gmt",
          cssClass: "substation-gmt",
          id: "GroundMountedTransformer",
          name: "Ground-mounted Transformer",
          size: 3,
          tool: "Marker",
          type: "transformer",
        },
      }));

      setNearbyAssets(assets);
    }
  };

  const onClick = (asset, assetNumber) => {
    const transformerSearchValue = getTransformerSearchValue(
      assetNumber,
      asset.name.replace(new RegExp(` \\((\\d+/)?${assetNumber}(/\\d+)?\\)`), ""),
    );

    setSelection(asset);

    if (!isReduxNetworkPopulated) {
      clientSettings.features.RAGCablesEnabled && showRagNetwork(asset, assetNumber);
    }

    setToolState({
      activeTab: tab.FILE,
      selectedAssetId: asset.id,
      searchValue: transformerSearchValue,
      feederList: null,
    });
  };

  const showRagNetwork = async (asset, assetNumber) => {
    setUseAutoZoomLevel(true);

    setToolState({
      isLoading: "Loading...",
    });

    const token = await getToken(instance, accounts);
    const response = await getRagNetwork(token, assetNumber);
    if (response instanceof Error) logException(response);

    const allWarnings = response?.network?.messages?.every((s) => s.level === "Warning");
    const anyMessages = response?.network?.messages?.length > 0;

    if (allWarnings && anyMessages) {
      setToolState({
        alert: {
          type: alert.WARNING,
          className: alert.WARNING,
          messages: response.network.messages,
        },
        isLoading: false,
      });
    }
    if (!allWarnings && anyMessages) {
      const name = asset.hasColocatedAssets ? assetNumber : asset.name;
      setToolState({
        alert: {
          type: alert.ERROR,
          className: alert.DANGER,
          messages: [
            {
              code: "1",
              description: "Sorry, unable to show the RAG network for " + name,
            },
          ],
        },
        isLoading: false,
      });
      return;
    }

    let ragNetwork;

    if (response.network.conductingEquipment.length === 0) {
      ragNetwork = createRagNetworkFromSubstation(asset);
    } else {
      ragNetwork = createRagNetworkFromResponse(response);
    }

    const _formState = { ...formState };
    _formState.ragNetwork = ragNetwork;
    _formState.ragNetwork.existing = false;
    _formState.ragNetwork.loaded = true;
    _formState.ragNetwork.substationName = asset.name;
    _formState.ragNetwork.substationId = asset.id;
    _formState.ragNetworks.push(_formState.ragNetwork);

    dispatch({
      form: "ragNetwork",
      obj: _formState.ragNetwork,
      type: "REPLACE_STATE",
    });

    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });

    dispatch({
      obj: _formState.ragNetworks,
      type: "SAVE_NETWORK",
    });

    setToolState({
      isLoading: false,
    });
  };

  const setSelection = (asset) => {
    if (selectedAsset) {
      const previousSelection = nearbyAssets.find((a) => a.id === selectedAsset.id);
      if (previousSelection) {
        previousSelection.styles.bgColor = "dark";
      }
    }
    asset.styles.bgColor = "primary";
    setSelectedAsset(asset);
  };

  const clearSelection = () => {
    if (!nearbyAssets || !selectedAsset) {
      return;
    }

    const assetToClear = nearbyAssets.find((a) => a.id === selectedAsset.id);

    if (assetToClear) {
      assetToClear.styles.bgColor = "dark";
    }

    setSelectedAsset(null);
  };

  const zoomToSelectedRagNetwork = () => {
    map.setZoom(
      formState.ragNetwork.cables.length > 0 &&
        formState.ragNetwork.cables.length <= SMALL_NETWORK_CABLE_THRESHOLD
        ? ragAssetSmallNetworkZoomLevel
        : ragAssetZoomLevel,
    );
    map.invalidateSize();
    setUseAutoZoomLevel(false);
  };

  const icon = (asset) => {
    if (asset.type === SUBSTATION) {
      return iconMarker(asset, mode, activeTool, iconSize);
    }
  };

  const shouldShowAssets = (type) => {
    if (toolState.showNearbyAssets.disabled) {
      return false;
    }

    if (type === SUBSTATION) {
      return toolState.showNearbyAssets.substations;
    }
  };

  return (
    <>
      {shouldShowAssets(SUBSTATION) &&
        nearbyAssets
          .filter((n) => !transformers.map((t) => t.substationId).includes(n.number))
          .filter((a) =>
            RAGCablesEnabled
              ? a.type === "substation" &&
                !formState.ragNetworks.find((r) => r.substationId === a.id)
              : a.type === "substation",
          )
          .map((substation) => (
            <Marker
              key={substation.key}
              id={substation.id}
              position={substation.position}
              title={substation.name}
              icon={icon(substation)}
              eventHandlers={{
                click: () => {
                  if (!substation.hasColocatedAssets) {
                    onClick(substation, substation.number);
                  }
                },
              }}
            >
              {substation.hasColocatedAssets && (
                <PopupMenu
                  menuItems={substation.colocatedIds.map((i) => `${i}`)}
                  onClick={(assetNumber) => {
                    onClick(substation, assetNumber);
                  }}
                ></PopupMenu>
              )}
            </Marker>
          ))}
    </>
  );
};

export default NearbyAssets;
