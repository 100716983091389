import { useState, useContext } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { FormContext } from "../context/FormContext";
import MsalSwitcher from "./MsalSwitcher";

const LogProvider = () => {
  const { formState } = useContext(FormContext);
  const [reactPlugin, setReactPlugin] = useState<ReactPlugin | undefined>(undefined);
  const enabled = formState?.clientSettings?.features?.FrontEndLoggingEnabled ?? false;
  const connectionString =
    formState?.clientSettings?.urls?.endPoints?.applicationInsightsConnectionString ?? "";

  if (enabled && connectionString && !reactPlugin) {
    const _reactPlugin = new ReactPlugin();
    setReactPlugin(_reactPlugin);

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: `${connectionString}`,
        extensions: [_reactPlugin],
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      },
    });
    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      envelope.tags["ai.cloud.role"] = "Front End";
    });
  }

  return (
    <>
      {reactPlugin ? (
        <AppInsightsContext.Provider value={reactPlugin}>
          <MsalSwitcher />
        </AppInsightsContext.Provider>
      ) : (
        <MsalSwitcher />
      )}
    </>
  );
};

export default LogProvider;
