import React, { useState, useContext } from "react";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";
import FaultFilterSubGroup from "./FaultFilterSubGroup";
import FaultFilterItem from "./FaultFilterItem";
import { FormContext } from "../context/FormContext";
import { Identifiers } from "./Identifiers";

const FaultFilterGroup = ({ faults, group, categories, onCheckboxBtnClick, defaultSeason }) => {
  const { formState } = useContext(FormContext);
  const { filterResults } = { ...formState.clientSettings };
  const [collapse, setCollapse] = useState(false);

  const checkForIdentifiers = (result) => {
    const identifiers =
      result.name === "Labels" || result.name === "Object IDs" || result.name === "Feeder ID";
    return identifiers;
  };

  const resultTransformer = (result) =>
    categories.find((child) => child.value === `${result.value}IncludeTransformers`);
  const isTransformersChildOption = (result) => result.value.endsWith("IncludeTransformers");

  const setValue = (e, result) => {
    Object.entries(e).forEach(([key, value]) => (result[key] = value));
    onCheckboxBtnClick(result);
  };

  const getFaults = (result) => {
    return faults.find(
      (f) => f.value === (result.season ? result.season + result.value : result.value),
    )?.constraintCount;
  };

  return (
    <ListGroup flush>
      <>
        {filterResults && (
          <>
            <ListGroupItem className="bg-dark" style={{ textTransform: "uppercase" }}>
              {group}
            </ListGroupItem>
            <>
              {categories.map((result) => (
                <React.Fragment key={result.id}>
                  {result.options ? (
                    <FaultFilterSubGroup
                      result={result}
                      faults={faults}
                      filterResults={filterResults}
                      setValue={(e) => setValue(e, result)}
                      defaultSeason={defaultSeason}
                    />
                  ) : checkForIdentifiers(result) ? (
                    result.name === "Labels" ? (
                      <>
                        <ListGroupItem
                          tag="a"
                          action
                          className="d-flex justify-content-between text-white"
                          onClick={() => setCollapse(!collapse)}
                        >
                          <span>Identifier</span>
                          <span className="list-group-item-icon">
                            <i
                              className={`icon-${collapse ? "chevron-down" : "chevron-right"}`}
                            ></i>
                          </span>
                        </ListGroupItem>
                        <Collapse isOpen={collapse}>
                          <Identifiers
                            result={result}
                            faults={faults}
                            getFaults={() => getFaults(result)}
                            setValue={(e) => setValue(e, result)}
                          />
                        </Collapse>
                      </>
                    ) : (
                      <Collapse isOpen={collapse}>
                        <Identifiers
                          result={result}
                          faults={faults}
                          getFaults={() => getFaults(result)}
                          setValue={(e) => setValue(e, result)}
                        />
                      </Collapse>
                    )
                  ) : isTransformersChildOption(result) ? (
                    <></>
                  ) : (
                    <FaultFilterItem
                      result={result}
                      faults={faults}
                      setValue={setValue}
                      defaultSeason={defaultSeason}
                      resultTransformer={resultTransformer(result)}
                    />
                  )}
                </React.Fragment>
              ))}
            </>
          </>
        )}
      </>
    </ListGroup>
  );
};
export default FaultFilterGroup;
