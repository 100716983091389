import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { ButtonGroup, Button, Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import { listConfigs } from "../services/apiService";
import { groupBy } from "../utils/groupBy";
import { FormContext } from "../context/FormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import useBearerToken from "../utils/useBearerToken";
import useUserInfoAndLogging from "../utils/useUserInfoAndLogging";

const ConfigurationSelection = ({ selectConfig, autoDetect, cancel }) => {
  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();
  const { logException } = useUserInfoAndLogging();

  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;

  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const getListConfigs = async () => {
      const token = await getToken(instance, accounts);
      listConfigs(token).then((p) => {
        if (p instanceof Error) logException(p);
        setVersions(p ?? []);
        setIsLoading(false);
      });
    };
    getListConfigs();
  }, []);

  return (
    <Modal isOpen={true} backdrop="static" keyboard={false} container="body">
      <ModalBody className="bg-dark text-light pb-0">
        <p>Which configuration would you like to use for this network?</p>
        <>
          {isLoading && (
            <div className="row justify-content-center my-3">
              <icon className="spinner icon-spinner-solid" />
            </div>
          )}
          {!isLoading && versions.length > 0 && (
            <Table id="version-table" className={`table-sm table-sortable text-light`}>
              <thead>
                <tr>
                  <th>Version</th>
                  <th colSpan={2}>Release Date</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(groupBy(versions, "clientName")).map((row) => (
                  <tr
                    key={`${row[0].clientName} ${row[0].versionNumber}`}
                    className={
                      row[0].clientName ===
                      clientSettings.general.defaultWinDebutConfigurationClient
                        ? "active"
                        : ""
                    }
                  >
                    <td class="align-middle">
                      {row[0].clientName} v{row[0].versionNumber}
                      {row[0].clientName ===
                        clientSettings.general.defaultWinDebutConfigurationClient && (
                        <span className="badge badge-success">Default</span>
                      )}
                    </td>
                    <td class="align-middle">{row[0].releaseDate}</td>
                    <td class="align-middle text-right">
                      <Button size="sm" color="primary" onClick={() => selectConfig(row[0])}>
                        Use
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {!isLoading && versions.length === 0 && (
            <div class="alert bg-danger" role="alert">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span className="ml-1">There was a problem getting the configurations</span>
            </div>
          )}
        </>
      </ModalBody>
      <ModalFooter className="bg-dark border-0">
        <ButtonGroup>
          <Button size="sm" color="secondary" className="mr-2" onClick={() => autoDetect()}>
            Custom Configuration
          </Button>
          <Button size="sm" color="secondary" onClick={() => cancel()}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default ConfigurationSelection;
