import { useContext, useState } from "react";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";

import { ToolContext } from "../context/ToolContext";
import IconRadioGrp from "./IconRadioGrp";

interface Fault {
  value: string;
  constraintCount: number;
}

interface ResultTransformer {
  isSet: boolean;
}

interface FaultFilterItemProps {
  result: any;
  faults: Fault[];
  setValue: (value: any, result: any) => void;
  defaultSeason?: string;
  resultTransformer?: ResultTransformer;
}

const FaultFilterItem = ({
  result,
  faults,
  setValue,
  defaultSeason,
  resultTransformer,
}: FaultFilterItemProps) => {
  const { toolState } = useContext(ToolContext);
  const [collapse, setCollapse] = useState(false);

  const getFaultCount = (): number => {
    return (
      faults.find((f) => f.value === (result.season ? result.season + result.value : result.value))
        ?.constraintCount ?? 0
    );
  };

  const toggle = (): void => {
    if (defaultSeason && result.season) {
      setValue({ isSet: !result.isSet, season: defaultSeason }, result);
    } else {
      setValue({ isSet: !result.isSet }, result);
    }
  };

  const toggleTransformer = (): void => {
    if (!!resultTransformer) {
      setValue({ isSet: !resultTransformer.isSet }, resultTransformer);
    }
  };

  return (
    <>
      <ListGroupItem
        tag="a"
        action
        className={`d-flex justify-content-between text-${result.isSet ? result.color : "black"}`}
        onClick={() => toggle()}
      >
        <span>
          {result.name}
          {faults.length > 0 && toolState.showResults && getFaultCount() > 0 && (
            <span className="badge badge-danger ml-1">{getFaultCount()}</span>
          )}
        </span>
        <span>
          {result.isSet && result.season && (
            <IconRadioGrp
              array={[
                { name: "summer", color: "warning", icon: "sun" },
                {
                  name: "winter",
                  color: "info",
                  icon: "snowflake",
                },
                { name: "All", color: "light", icon: "all" },
              ]}
              value={result.season}
              setValue={(e: any) => setValue(e, result)}
            />
          )}
          <span className="list-group-item-icon">
            <i className={`icon-${result.isSet ? "check" : "cross"}-solid`}></i>
          </span>
          {!!resultTransformer && (
            <span
              className="list-group-item-icon"
              onClick={(event) => {
                setCollapse(!collapse);
                event.stopPropagation();
              }}
            >
              <i className={`icon-${collapse ? "chevron-down" : "chevron-right"}`}></i>
            </span>
          )}
        </span>
      </ListGroupItem>
      {!!resultTransformer && (
        <Collapse isOpen={collapse}>
          <ListGroup flush>
            <ListGroupItem
              tag="a"
              action
              className={`d-flex justify-content-between text-${
                resultTransformer.isSet ? result.color : "black"
              }`}
              onClick={() => toggleTransformer()}
            >
              <span className="mr-4">Include Transformers</span>
              <span className="list-group-item-icon">
                <i className={`icon-${resultTransformer.isSet ? "check" : "cross"}-solid`}></i>
              </span>
            </ListGroupItem>
          </ListGroup>
        </Collapse>
      )}
    </>
  );
};
export default FaultFilterItem;
