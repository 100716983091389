import { MsalProvider } from "@azure/msal-react";
import "./App.css";
import LogProvider from "./components/LogProvider";
import ToolProvider from "./context/ToolContext";
import FormProvider from "./context/FormContext";
import { store } from "./app/store";
import { Provider } from "react-redux";

const B2XApp = ({ pca }) => {
  return (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <ToolProvider>
          <FormProvider>
            <LogProvider />
          </FormProvider>
        </ToolProvider>
      </MsalProvider>
    </Provider>
  );
};

export default B2XApp;
