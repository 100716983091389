import * as linkBoxConstants from "../constants/linkBox";
import { listConnectedCables } from "../utils/linkBoxFunctions";
import { getConstraint } from "../services/constraintsService";

const resultIsEnabled = (isTransformer, result, allResultsInCategory) => {
  if (!result.isSet) {
    return false;
  }
  if (isTransformer) {
    const resultTransformer = allResultsInCategory.find(
      (child) => child.value === `${result.value}IncludeTransformers`,
    );
    if (resultTransformer) {
      if (!resultTransformer.isSet) {
        return false;
      }
    }
  }
  return true;
};

export const parseResults = (
  asset,
  clientSettings,
  networkCables,
  clickedAsset,
  hasClearedResults,
) => {
  const linkBox = clickedAsset?.linkBox;
  const isTransformer = asset.styles.type === "transformer";

  const results = [];

  if (
    clickedAsset &&
    asset.startAssetId &&
    asset.endAssetId &&
    linkBox?.numberOfWays === linkBoxConstants.FOURWAY &&
    (asset.annotation?.length ?? 0) === 0
  ) {
    const cables = listConnectedCables(networkCables, clickedAsset);

    const title = cables.find((p) => p.cableId === asset.id)?.title;

    if (title?.length > 0) {
      results.push({
        category: "annotation",
        name: "PseudoAnnotation",
        value: title,
        unit: "",
        class: "white",
        constraint: false,
        hide: false,
      });
    }
  }

  const fuseSizeFactor = (asset, value, res) => {
    let val = asset[value];
    if (res.name === "Fuse Size (A)") {
      val = asset["feederFuseSize"];
    }

    return val;
  };

  clientSettings.filterResults.forEach((category) => {
    category.info.forEach((res, i, allResultsInCategory) => {
      if (resultIsEnabled(isTransformer, res, allResultsInCategory)) {
        const value = res.season ? res.season + res.value : res.value;
        if (asset[value]) {
          results.push({
            name: res.name,
            value: fuseSizeFactor(asset, value, res),
            category: res.value,
            unit: res.unit,
            class: res.color,
            constraint: getConstraint(asset, value),
            hide:
              (hasClearedResults && res.name === "Fuse Size (A)") ||
              (asset.isFirstLeg === "false" &&
                (res.name === "Identifiers" ||
                  (!asset.hasFuseSize && res.name === "Fuse Size (A)"))),
          });
        }
        if (res.value === "feederFuseSize") {
          if (asset.hasFuseWarning) {
            results.push({
              category: "feederFuseSize",
              name: "Fuse Warning",
              value: "Fault level",
              unit: "",
              class: "sea-green",
              constraint: true,
              hide: false,
            });
          }
        }
      }
    });
  });

  if (asset.hasFuseMaxCurrentWarning) {
    let currentResult = results.filter((r) => r.name === "Fuse Size (A)")[0];
    if (currentResult) {
      currentResult.class = "sea-green";
      currentResult.constraint = true;
    }
  }

  return results;
};

const getDisplayName = (res) => {
  const unitString = `(${res.unit})`;
  let name = res.name;
  name = name.replace(unitString, "").trimEnd();
  const option = res.options?.find((opt) => opt.value === res.value);
  if (option) {
    var optionName = option.name.replace(unitString, "").trimEnd();
    if (optionName.startsWith(name)) {
      optionName = optionName.substring(name.length).trimStart();
    }
    if (optionName.startsWith("- ")) {
      optionName = optionName.substring(2).trimStart();
    }
    name += ": " + optionName;
  }
  return name;
};

export const parseResultsForPopup = (asset, clientSettings) => {
  const results = [];
  const isTransformer = asset.styles.type === "transformer";

  const fuseSizeFactor = (asset, value, res) => {
    let val = asset[value];
    if (res.name === "Fuse Size (A)") {
      val = asset["feederFuseSize"];
    }

    return val;
  };

  clientSettings.filterResults.forEach((category) => {
    category.info.forEach((res, i, allResultsInCategory) => {
      if (resultIsEnabled(isTransformer, res, allResultsInCategory)) {
        const value = res.season ? res.season + res.value : res.value;
        if (asset[value]) {
          results.push({
            name: getDisplayName(res),
            value: fuseSizeFactor(asset, value, res),
            category: res.value,
            unit: res.unit,
            class: res.color,
            constraint: getConstraint(asset, value),
          });
        }
        if (res.value === "feederFuseSize") {
          if (asset.hasFuseWarning) {
            results.push({
              category: "feederFuseSize",
              name: "Fuse Warning",
              value: "Fault level",
              unit: "",
              class: "sea-green",
              constraint: true,
            });
          }
        }
      }
    });
  });

  if (asset.hasFuseMaxCurrentWarning) {
    let currentResult = results.filter((r) => r.name === "Fuse Size")[0];
    if (currentResult) {
      currentResult.class = "sea-green";
      currentResult.constraint = true;
    }
  }

  return results;
};
