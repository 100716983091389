import { useContext, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import "leaflet/dist/leaflet.css";
import { FormContext } from "../context/FormContext";
import ConnectLV from "./ConnectLV";
import { getReference, authorise, getClientSettings } from "../services/apiService";
import useMsalEvents from "../useMsalEvents";
import { loginRequest } from "../authConfig";
import { store } from "../app/store";
import { updateMaxHistory } from "../app/undoable";
import useUserInfoAndLogging from "../utils/useUserInfoAndLogging";
import useBearerToken from "../utils/useBearerToken";

const MsalSwitcher = () => {
  const [authorised, setAuthorised] = useState(false);
  const [settings, setSettings] = useState();
  const { instance, accounts } = useMsal();
  const { logMetric, logException } = useUserInfoAndLogging();
  const getToken = useBearerToken();

  const { REACT_APP_B2B, REACT_APP_B2C } = process.env;
  const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";

  useMsalEvents();

  useEffect(() => {
    if (accounts.length > 0 || !b2x) {
      const replaceClientSettings = async () => {
        const token = await getToken(instance, accounts);
        const _clientSettings = await getClientSettings(token);
        if (_clientSettings instanceof Error) {
          logException(_clientSettings);
        } else {
          logMetric("User", "Read Settings");
        }
        document.title = _clientSettings.general.displayName;
        const attribute = document.querySelector("html");
        attribute.style.setProperty(
          "--newColourblindMarkerColour",
          _clientSettings.general.stylingNewStatusAssetColourBlindColour,
        );
        attribute.style.setProperty(
          "--newMarkerColour",
          _clientSettings.general.stylingNewStatusAssetColour,
        );
        attribute.style.setProperty(
          "--replacementColourblindMarkerColour",
          _clientSettings.general.stylingReplacementStatusAssetColourBlindColour,
        );
        attribute.style.setProperty(
          "--replacementMarkerColour",
          _clientSettings.general.stylingReplacementStatusAssetColour,
        );
        dispatch({
          form: "clientSettings",
          obj: _clientSettings,
          type: "REPLACE_STATE",
        });
        const reference = await getReference(token);
        if (reference instanceof Error) {
          logException(reference);
        }
        dispatch({
          form: "reference",
          obj: reference,
          type: "REPLACE_STATE",
        });
        store.dispatch(updateMaxHistory(_clientSettings.general.maxHistory));
        setSettings(_clientSettings);
        b2x && authoriseUser(token);
      };
      replaceClientSettings();
    }
  }, [accounts]);

  const authoriseUser = (token) => {
    authorise(token)
      .then((response) => {
        if (response.data.messages) {
          console.log(response.data);
          setAuthorised(false);
          return;
        }
        console.log(response);
        setAuthorised(true);
      })
      .catch((error) => {
        logException(error);
        setAuthorised(false);
        instance.logoutRedirect();
      });
  };

  const { dispatch } = useContext(FormContext);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      {b2x ? (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          {settings && authorised && <ConnectLV />}
        </MsalAuthenticationTemplate>
      ) : (
        settings && <ConnectLV />
      )}
    </>
  );
};

export default MsalSwitcher;
