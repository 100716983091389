import "./App.css";
import AuthSwitcher from "./components/login/AuthSwitcher";
import LogProvider from "./components/LogProvider";
import ToolProvider from "./context/ToolContext";
import FormProvider from "./context/FormContext";
import { store } from "./app/store";
import { Provider } from "react-redux";

const App = () => {
  const { REACT_APP_BA } = process.env;
  const ba = REACT_APP_BA === "true";

  return (
    <Provider store={store}>
      <ToolProvider>
        <FormProvider>{ba ? <AuthSwitcher /> : <LogProvider />}</FormProvider>
      </ToolProvider>
    </Provider>
  );
};

export default App;
