import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useMsal } from "@azure/msal-react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getLocalStorageValue, setLocalStorageValue } from "./useLocalStorage";
import { v4 as uuid } from "uuid";

const useUserInfoAndLogging = () => {
  const { accounts } = useMsal();
  const appInsights = useAppInsightsContext();
  const { REACT_APP_B2B, REACT_APP_B2C } = process.env;
  const b2x = REACT_APP_B2B === "true" ? "b2b" : REACT_APP_B2C === "true" ? "b2c" : "";
  const enabled = !!appInsights;

  const getAccountId = (): string => {
    if (!accounts.length) return "";
    const { accountId, oid } = accounts[0].idTokenClaims as any;
    return b2x === "b2b" ? oid : b2x === "b2c" ? accountId[0] : "";
  };

  const getSessionId = (): string => {
    let sessionId = getLocalStorageValue("sessionId", undefined);
    if (!sessionId) {
      sessionId = uuid();
      setLocalStorageValue("sessionId", sessionId);
    }
    return sessionId;
  };

  const logMetric = (name: string, action: string): void => {
    if (!enabled) {
      return;
    }

    try {
      const metricData = {
        average: 1,
        name: name,
        sampleCount: 1,
      };
      const additionalData = {
        "Account Id": getAccountId(),
        "Session Id": getSessionId(),
        Action: action,
      };
      appInsights.trackMetric(metricData, additionalData);
    } catch (err) {
      console.log(err);
    }
  };

  const logException = (error: any): void => {
    if (!enabled) {
      return;
    }
    console.log(error);
    try {
      const exceptionData = {
        error: new Error(error),
        severityLevel: SeverityLevel.Error,
      };
      const additionalData = {
        "Account Id": getAccountId(),
        "Session Id": getSessionId(),
      };
      appInsights.trackException(exceptionData, additionalData);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getAccountId,
    getSessionId,
    logMetric,
    logException,
  };
};

export default useUserInfoAndLogging;
